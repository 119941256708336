import React, { useEffect, useState, useRef } from 'react';
import logo from './LogoClearInverted.png'
import bell from './bell.png'
import './App.css';

const regularOptions = [
  'אתה מוזמן ליצור איתנו קשר בכל בעיה'
]

function App() {
  const phoneInput = useRef(null);
  const textInput = useRef(null);
  const selectInput = useRef(null);
  const [textAreaValue, setTextAreaValue] = useState('')
  const [options, setOptions] = useState([])

  useEffect(() => {
    const localOptions = localStorage.getItem('selectOptions') ? JSON.parse(localStorage.getItem('selectOptions')) : [];
    setOptions([...localOptions, ...regularOptions])
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    let val = phoneInput.current && phoneInput.current.value;

    if (!val || val.length < 9) {
      alert(' מספר טלפון לא תקין');
      return;
    }
    if (!(val.startsWith('+972') || val.startsWith('972'))) {
      val = '972' + val;
    }
    const newText = textAreaValue.split(' ').join('%20');

    window.open(`https://api.whatsapp.com/send?phone=${val}&text=${encodeURIComponent(newText).replace(/%20/g, '+')}`);
  }

  const handleAddClick = e => {
    e.preventDefault();
    if (options.includes(textAreaValue)) return;
    const localOptions = localStorage.getItem('selectOptions') ? JSON.parse(localStorage.getItem('selectOptions')) : [];
    localStorage.setItem('selectOptions', JSON.stringify([textAreaValue, ...localOptions]));
    setOptions([textAreaValue, ...options]);
  }

  const handleOptionClick = e => {
    setTextAreaValue(e.target.value);
  }

  const handleTextAreaChange = e => {
    setTextAreaValue(e.target.value);
  }

  return (
    <div className="App">
      <div className="App-header">
        <div className='bell-header-container'>
          <img src={bell} alt='bell' className='bell-header'></img>
        </div>
        <h2>שליחת הודעה בוואטסאפ</h2>
        <img src={logo} alt='logo' className='image-header'></img>
      </div>
      <div className='App-body'>
        <form onSubmit={handleSubmit} className='App-form'>
          <div className='flex-container'>
            <p> (ללא קידומת וללא מקפים)</p>
            <p className='first-text'>הכנס מספר טלפון</p>
          </div>
          <input placeholder=' לדוגמא 0541234567' type="text" ref={phoneInput} type='tel' />
          <div className='flex-container-2'>
            <select name="select" className='templates' onChange={handleOptionClick} ref={selectInput} >
              <option></option>
              {options.map((item, index) => (
                <option key={index}>
                  {item}
                </option>
              ))}
            </select>
            <button onClick={handleAddClick} className='App-add-button'>{'  <--  ' + '   הוסף לרשימה'}</button>
            <textarea placeholder='הודעה לדוגמא' onChange={handleTextAreaChange} value={textAreaValue} type='text' ref={textInput} />
          </div>
          <button type='submit' className='App-form-button'>
            <p className='app-form-button-text'>{'מעבר לוואטסאפ'}</p>
          </button>
        </form>

      </div>
    </div>
  );
}

export default App;
